import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { FluidImage } from '@atoms/Image'
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
import { Section, Title, Paragraph, Column } from "@blocks/page/Section"
import NewsList from "@blocks/page/NewsList"
import { ContentFooter } from "@projects/ContentFooter"
import css from "@css/projects/page/p-content.module.styl"

export default function News() {
    const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "pageVision__img__hero@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			message : file(relativePath: { eq: "pageVision__img__message@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            newsData: allMicrocmsNews(
                sort: { fields: date, order: DESC }
            ) {
                edges {
                    node {
                        content
                        date(formatString: "YYYY.MM.DD")
                        title
                        slug
                        newsId
                        is_private
                    }
                }
            }
        }
    `)
    const data = q.newsData.edges
    return (
        <>
            <Seo subtitle={'NEWS'} />
            <Hero
                image={q.main01}
                label='お知らせ'
                title1='News'
                title2=''
                links={[
                    {
                        label: 'ニュース一覧',
                        scroll: 'list'
                    }
                ]}
            />
            <div className={css.container}>
                <div className={css.inner}>
                    <BreadcrumbList title="お知らせ" />
                    <Section id="list">
                        <NewsList data={data}/>
                    </Section>
                </div>
            </div>
            <ContentFooter
                banner={{
                    img: q.main01,
                    label: 'Recruit',
                    title: '採用情報',
                    to: '/recruit/',
                }}
            />
        </>
    )
}
